.ant-typography kbd {
  margin: 0 0.2em;
  padding: 0.5em 0.5em;
  font-size: 90%;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
}

.primary {
  color: $primary-alt;
}

.ant-form-item-label > label {
  font-size: 12px;
}

.ant-form .ant-col-8 {
  max-width: 100%;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
  padding: 0;  
}

.ant-form .ant-col-16 {  
  max-width: 100%;
}