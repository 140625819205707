.login-container {
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 530px;
  flex-direction: column;

  height: calc(100% - #{$body-padding});

  // medium -> tablet
  @media (min-width: 577px) and (max-width: 1200px) {
    max-width: 400px;
  }

  // small phone
  @media (max-width: 576px) {
    max-width: initial;

    h1.ant-typography {
      font-size: 40px;
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    margin-top: 60px;
  }

  .ant-form {
    .ant-input {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;

      &:not(.ant-input-disabled) {
        &:focus,
        &:hover {
          box-shadow: none !important;
          border-color: $primary-main !important;
        }
      }
    }
  }

  .reset-password {
    width: 100%;
  }

  .btn-login {
    font-weight: 700;
    padding: 4px 6px;
    font-size: calc(0.2vw + 12px);
    width: 160px;
    text-transform: uppercase;
  }

  .btn-facebook-login {
    font-family: Helvetica, sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: calc(0.2vw + 12px);
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.3s, border-color 0.3s;
    background-color: #4c69ba;
    border: calc(0.06887vw + 0.67769px) solid #4c69ba;
    padding: 4px 6px 1px;
    width: 160px;
  }

  .btn-google-login {
    font-family: Helvetica, sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: calc(0.2vw + 12px);
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.3s, border-color 0.3s;
    background-color: #cf4331;
    border: calc(0.06887vw + 0.67769px) solid #cf4331;
    padding: 4px 20px 1px;
    width: 160px;
  }

  .btn-facebook-login > span {
    padding-right: 6px;
  }
}
