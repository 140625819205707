// primary colors
$primary-main: #f3f3f3;
$primary-alt: #3276b1;
$dark: #3A3634;
$gray: #6d6e71;
$light-gray: #e4e4e4;
$off-white: #fefefe;
$required: #ff0000;

// text
$text-dark: rgba(0, 0, 0, 0.65);

// secondary colors
$secondary: #F58026;
$danger: #ff0000;

// inactive state
$primary-inactive: rgba($primary-main, 0.3);

// background color
// $background-dev: #facbc3;
// $background-staging: #4b4c4e;
$background-dev: $light-gray;
$background-staging: $light-gray;

//ant colors
$ant-border-color: #d9d9d9;
$ant-table-border-color: #e8e8e8;
