.ant-layout {
  background: transparent;
  height: 100%;
}

.ant-table-wrapper {
  @media (max-width: 767px) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #e8e8e8;
    table {
      border: none;
    }
  }
}

.ant-list {
  .ant-list-header {
    background: #f3f3f3;
  }
}

.ant-layout-sider,
.ant-drawer-content {
  background-color: $dark;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}

.ant-drawer-header {
  background-color: $off-white;
}

.ant-menu {
  background-color: #dbddde;

  .ant-menu-item-divider {
    background-color: $off-white;
    margin: 0 20px;
  }
}

.small-panel {
  .ant-collapse-content-box {
    // padding: 0;
    padding-block: 0 !important;
  }
}

// .anticon {
//   font-size: 1.3em;
// }

@import "header";
@import "button";
@import "title";
@import "form";
@import "calendar-picker";
@import "divider";
@import "dropdown";
@import "steps";
@import "drawer";
@import "menu";
@import "typography";
@import "table";
