.ant-drawer {
    color: white;
    
    .ant-drawer-header {
        max-height: 64px;
        height: 64px;
        background: $primary-main;
        padding: 0 20px;
    }

    .ant-drawer-title {
        padding-top: 20px;
    }
    .ant-drawer-content {
        background-color: $off-white;
    }

    .ant-drawer-title {
        height: 64px;
    }

    .navbar-logo-container {
        width:200px;
        height: 64px;
        display: inline-block;
    }

    .navbar-logo {    
      vertical-align: middle;
    }
}

