.list {
  border: 1px solid #d9d9d9;
  border-radius: 4px;

  .list-item {
    padding: 12px 24px;
    border-bottom: 1px solid #d9d9d9;

    &.title {
      background: #FAFAFA;
      text-align: left;
      font-size: 17px;
      font-weight: 700;
    }

    .list-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;

      .list-label {
        width: 100%;
        margin-right: 20px;
      }

      .ant-form-item {
          margin: 0;
      }
    }
  }
}
