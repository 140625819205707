.ant-menu {
  background-color: $dark;

  .ant-menu-title-content,
  .ant-menu-item {
    color: $off-white;

    a {
      color: $off-white;

      &:hover {
        color: $off-white;
      }

      &:link,
      &:active,
      &:focus {
        color: $off-white;

        span {
          color: $off-white;
        }
      }
    }
  }

  .ant-menu-item-only-child {
    color: $off-white;
  }

  .ant-menu-submenu,
  .ant-menu-submenu-active {
    color: $off-white;

    .ant-menu {
      background-color: lighten($dark, 5%);
    }
  }
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: $off-white;
}

.ant-menu-inline > .ant-menu-item,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 25px;
  line-height: 25px;
}

.ant-menu .ant-menu-item-divider {
  margin: 6px 20px;
}

.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after {
  background-image: linear-gradient(to right, $off-white, $off-white);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: lighten($dark, 20%);
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid $off-white;
}

.side-nav {
  position: fixed;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  margin-top: 64px;
}

.side-nav::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.side-nav::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #777777;
  border-radius: 10px;
}

/* Handle */
.side-nav::-webkit-scrollbar-thumb {
  background: #777777;
  border-radius: 10px;
}

/* Handle on hover */
.side-nav::-webkit-scrollbar-thumb:hover {
  background: #555555;
}

.side-nav + section {
  position: relative;
  margin-left: 400px;
}

.side-nav.ant-layout-sider-collapsed + section {
  margin-left: 0;
}
