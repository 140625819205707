.wrapper {
    padding-top: $body-padding;
    @media (max-width: 767px) {
        padding-top: $navbar-height + 10px;
    }

    .site-layout {
        margin-left: 400px;
    }

    .ant-layout-sider-collapsed + .site-layout {
        margin-left: 0px;
    }
}

.wrapper-simple {
    padding-top: $body-padding-simple;
    @media (max-width: 767px) {
        padding-top: $body-padding-simple + 10px;
    }
}

.ant-menu.ant-menu-horizontal {
    background-color: transparent;
}

.ant-menu-horizontal > .ant-menu-item:hover {
    color: $off-white;
    border-bottom-color: $primary-alt;
}

.ant-menu-horizontal > .ant-menu-item a,
.ant-menu-horizontal > .ant-menu-item a:hover {
    color: $off-white;
}

.navbar {
    align-items: center;
    border-bottom: 1px solid $light-gray;
    margin-bottom: 10px;

    background: $primary-main;
    color: $text-dark;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    .navbar-logo-container {
        width: 200px;
        color: $text-dark;
    }

    .navbar-logo {
        vertical-align: middle;
    }

    .account-container {
        display: flex;
        align-items: center;
        cursor: pointer;

        .user-icon {
            margin-right: 10px;
            font-size: 25px;
        }
        .down-icon {
            margin-left: 10px;
        }
    }

    @media (max-width: 590px) {
        padding: 0 15px;

        .navbar-logo {
            height: 31px;
        }

        .account-container {
            .user-icon {
                margin-right: 0;
                font-size: 20px;
            }
            .account-username {
                display: none;
            }
            .down-icon {
                margin-left: 0;
            }
        }
    }
}

//navbar responsive
.navbar {
    .anticon-menu {
        font-size: 1.3rem;
    }

    .responsive-container {
        .mobile {
            display: none;
            .dropdown {
                margin-bottom: 20px !important;
                width: 100%;
            }
        }

        font-size: 14px;
        font-weight: 500;

        .link-row {
            margin-left: 40px;
            a {
                color: $off-white;
                margin-left: 20px;

                &.active {
                    color: $off-white;
                }

                &:hover,
                &:active,
                &:focus {
                    color: $off-white;
                    text-decoration: none;
                }
            }
        }

        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        width: 100%;

        @media (max-width: 767px) {
            width: auto;

            .mobile {
                display: flex;
                align-items: center;
            }
            .desktop {
                display: none;
            }
        }
    }
}
