.ant-table.ant-table-small {
  font-size: 12px;
}

.ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, 
.ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
  padding: 4px;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
  border: 1px solid #cccccc;
}