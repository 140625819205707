
#client-report > div > div.gcv-menu {
    height:unset;
  }
  
  
  #active-report > div > div.gcv-menu {
    height:unset;
  }
  
  #active-report > div.gc-app.gc-viewer{
    min-height: calc(100vh - 300px);
  }
  
  